<template>
    <v-app>
        <iframe :src="iframeUrl"
            width="100%" height="100%"></iframe>
    </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {
  },
  data() {
    return {
      iframeUrl: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  mounted() {
    this.iframeUrl = `https://bizli.retool.com/embedded/public/2a1ad1eb-6872-46f5-801f-faf749e801d2?email=${this.user.email}`;
  },
};
</script>
